import { Box, SimpleGrid, Spinner, Text } from '@chakra-ui/core'
import React, { FC, useContext } from 'react'
import { brandColourAsText, fontSizeH3, fontSizeH4 } from '../../../styles'
import { VenueCard } from '../../../components/venue-card'
import { GroupConfigContext } from '../../../providers/group-config'

/**
 * Reduce array by common properties
 *
 * @param objectArray
 * @param property1
 * @param property2
 */
const groupBy = (objectArray, property1, property2) => {
  return objectArray.reduce((acc, obj) => {
    let key = obj?.[property1]?.[property2]

    // So things with no key still get included
    if (key === null || key === undefined) {
      key = 'Other'
    }

    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

/**
 * Display venue cards grouped by state.
 * Click cards to go to venue.
 *
 * @example <LandingItem />
 */
export const LandingItem: FC = () => {
  const { restaurants, loading, configForGroup } = useContext(GroupConfigContext)
  const { title = null } = configForGroup || {}

  if (!restaurants) return null

  const venuesByState = groupBy(restaurants, 'location', 'state')

  if (loading) {
    return (
      <Box justifyContent='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' size='xl' {...brandColourAsText} />
      </Box>
    )
  }

  return (
    <>
      <Text lineHeight='normal' textAlign='center' position='relative' {...fontSizeH3} mb='50px' padding='50px' overflow='hidden'>
        Choose the store you want to order from
        <Text
          as='span'
          color='rgba(0,0,0,0.03)'
          fontSize='8rem'
          fontWeight='500'
          left='50%'
          letterSpacing='8px'
          lineHeight='0'
          position='absolute'
          top='50px'
          transform='translate(-50%, -50%)'
          userSelect='none'
          width='9999px'
          zIndex={0}
        >
          {title}
        </Text>
      </Text>

      {Object.keys(venuesByState).map((state) => (
        <React.Fragment key={state}>
          {Object.keys(venuesByState).length > 1 && (
            <Text textAlign='center' {...fontSizeH4} {...brandColourAsText} mt={6} mb={5}>
              {state}
            </Text>
          )}
          <SimpleGrid columns={[1, 2, null, 3]} spacing='2rem'>
            {venuesByState[state].map((venue) => (
              <React.Fragment key={venue.name}>{VenueCard(venue)}</React.Fragment>
            ))}
          </SimpleGrid>
        </React.Fragment>
      ))}
    </>
  )
}
