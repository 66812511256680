/** Feature overrides for specific venues.
 * Ideally we wouldn't want these, but this was the simpler short-term solution.
 * TODO build feature flags as a proper feature, even if only on the frontend
 */
import moment from 'moment'
import React from 'react'
import { FulfilmentType } from '../models'
import { InitialPopup } from '../components/initial-popup'

const CHEFS_GALLERY_DEMO_ID = 'AA37425E-FA40-11EB-B0B5-0206360F674A' // Chefs Gallery Delivery
const OPPA_POCHA_DEMO_ID = '684C5C76-0929-11EC-B5FA-0A126FF89AFE'
const MAMASAAN_ID = '4CD3ADC6-4150-4EF8-8C76-CECD266E7EE6'

const CHEFS_GALLERY_MACQUARIE_ID = '841BBE50-321A-746F-FF2D-856CBA37FC00' // Chefs Gallery Macquarie
const CHEFS_GALLERY_TOWN_HALL_ID = '3A94DE24-CE76-6B52-FFF9-6EB653160400' // Chefs Gallery Town Hall
const CHEFS_GALLERY_PYRMONT_ID = '3E9A7E1D6-F6CA-4AD5-A9A7-516DED83616A' // Chefs Gallery Pyrmont

const getChefsGalleryDeliveryDays = () => {
  return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
}

const getChefsGalleryClosedDates = () => {
  return ['25-12-2022', '01-01-2023', '02-01-2023']
}

export const isLockedToDelivery = (restaurant) => {
  return restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID
}

export const getMinimumDelivery = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID ) {
    return 200
  }

  if (restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 80
  }

  return 0
}

export const meetsMinimumDelivery = (restaurant, subtotal) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return subtotal >= getMinimumDelivery(restaurant)
  }

  return true
}

/**
 * How many days in advance must be the order be placed?
 */
export const orderDaysInAdvance = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 1
  }

  return 0
}

/**
 * At what point are orders cut off for the day?
 */
export const getNightlyCutoff = (restaurant): number | boolean | any => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 2030
  }

  return false
}

export const afterNightlyCutoff = (time, restaurant) => {
  const nightlyCutoff = getNightlyCutoff(restaurant)

  if (nightlyCutoff === false) {
    return false
  }

  const nightlyCutoffTime = moment().set({ hour: nightlyCutoff / 100, minute: nightlyCutoff % 100 })

  return time >= nightlyCutoffTime
}

/**
 * How many days in advance max can people make their orders?
 */
export const getMaxBookingDays = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 28
  }

  return 6
}

export const getInvalidDateMessage = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return {
      title: 'Please choose another date',
      message: 'There are no delivery postcodes for the chosen date',
    }
  }

  return {
    title: 'Incorrect Time',
    message: 'You cannot order outside of the opening hours',
  }
}

export const hasCustomPostcodes = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return true
  }

  return false
}

/**
 * Chef's Gallery uses a different set of postcodes every day.
 * user's postcode must be in the list for the day of their order
 */
const validateChefsGalleryPostcodes = (restaurant, postcodeToCheck) => {
  const postcodeAsInt = parseInt(postcodeToCheck);
  const validPostcodes = [
    2000,
    2007,
    2008,
    2010,
    2011,
    2015,
    2016,
    2017,
    2021,
    2037,
    2038,
    2039,
    2040,
    2041,
    2042,
    2043,
    2047,
    2050,
  ];

  return  validPostcodes.includes(postcodeAsInt);
}

/**
 * If a restaurant has custom delivery days return them
 */
export const getDeliveryDays = (restaurant) => {
  switch (restaurant?.objectId) {
    case CHEFS_GALLERY_DEMO_ID:
    case OPPA_POCHA_DEMO_ID:
      return getChefsGalleryDeliveryDays()
    default:
      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }
}

/**
 * Chef's Gallery uses a different set of postcodes every day.
 * user's postcode must be in the list for the day of their order
 */
const getChefsGalleryPostcodeErrorMessage = (restaurant, postcodeToCheck, orderDate) => {
  const validDays = getDeliveryDays(restaurant)

  const isValidPostcode = validateCustomPostcodes(restaurant, postcodeToCheck);
  const orderDay = moment(orderDate).format('dddd').toLowerCase()

  if (!postcodeToCheck) {
    return 'You must set your delivery address to proceed with this order'
  }

  if (!validDays?.includes(orderDay)) {
    return `Delivery is not available on ${orderDay}`
  }

  if (!isValidPostcode) {
    return `Postcode ${postcodeToCheck} is not a valid delivery postcode for this restaurant.`
  }

  return 'Your postcode is invalid for this delivery'
}

/**
 * Validates the postcode for a restaurant with custom postcode rules.
 * e.g. Chef's Gallery has a different set of postcodes each day.
 */
export const validateCustomPostcodes = (restaurant, postcodeToCheck) => {
  switch (restaurant?.objectId) {
    case CHEFS_GALLERY_DEMO_ID:
    case OPPA_POCHA_DEMO_ID:
      return validateChefsGalleryPostcodes(restaurant, postcodeToCheck)
    default:
      return true
  }
}

export const isClosedDateForRestaurant = (restaurant, date) => {
  const orderDate = moment(date).format('DD-MM-YYYY')
  const closedDates = getChefsGalleryClosedDates()

  switch (restaurant?.objectId) {
    case CHEFS_GALLERY_DEMO_ID:
    case CHEFS_GALLERY_MACQUARIE_ID:
    case CHEFS_GALLERY_TOWN_HALL_ID:
    case CHEFS_GALLERY_PYRMONT_ID:
      return closedDates?.includes(orderDate)
    default:
      return false
  }
}

export const isValidDateForRestaurant = (restaurant, date) => {
  switch (restaurant?.objectId) {
    case CHEFS_GALLERY_DEMO_ID:
    case OPPA_POCHA_DEMO_ID:
      // eslint-disable-next-line no-case-declarations
      const orderDay = moment(date).format('dddd').toLowerCase()
      // eslint-disable-next-line no-case-declarations
      const deliveryDays = getChefsGalleryDeliveryDays()
      return deliveryDays?.includes(orderDay)
    default:
      return true
  }
}

/**
 * Returns an error message appropriate for the custom postcode validation used
 */
export const getPostcodeValidationErrorMessage = (restaurant, postcodeToCheck, orderDate) => {
  switch (restaurant?.objectId) {
    case CHEFS_GALLERY_DEMO_ID:
    case OPPA_POCHA_DEMO_ID:
      return getChefsGalleryPostcodeErrorMessage(restaurant, postcodeToCheck, orderDate)
    default:
      return 'Postcode is invalid'
  }
}

export const getDefaultPickupType = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return FulfilmentType.DELIVERY
  }

  return FulfilmentType.PICKUP
}

export const getFulfilmentTypes = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return [FulfilmentType.DELIVERY]
  }

  return [FulfilmentType.PICKUP, FulfilmentType.DELIVERY, FulfilmentType.DINE_IN]
}

export const hasDefaultFulfilmentType = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return true
  }
}

export const getDefaultFulfilmentType = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return FulfilmentType.DELIVERY
  }

  return FulfilmentType.PICKUP
}

export const getFulfilmentButtonLabel = (restaurant, type) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 'Address'
  }

  return type
}

export const mustChooseDeliveryDate = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return true
  }

  return false
}

export const hasLockedOrderTime = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return true
  }

  return false
}

export const getLockedOrderTime = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return '14:00'
  }

  return ''
}

export const getLockedOrderTimeHumanReadable = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return '2pm - 6pm'
  }

  return ''
}

export const getCustomTimeLabel = (restaurant, preferredDateTime) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return `${moment(preferredDateTime).format('dddd')} 2pm - 6pm`
  }

  return ''
}

export const getTimeFormat = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return '[2pm - 6pm]'
  }

  return 'h:mm a'
}

export const hasDisplayLocation = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return false
  }

  return true
}

export const getSupportURL = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID) {
    return 'https://www.chefsgallery.com.au/'
  }

  if (restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 'https://www.oppapocha.com.au/'
  }

  return ''
}

export const getSupportText = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 'Support'
  }

  return 'Support'
}

export const getPickupTime = (restaurant, order) => {
  if (hasLockedOrderTime(restaurant)) {
    return getLockedOrderTimeHumanReadable(restaurant)
  }

  return order.pickupTime
}

export const allowChangingDeliveryAddressAtCheckout = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return false
  }

  return true
}

// God I hate this
export const getInitialPopupContent = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID || restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return <InitialPopup />
  }

  return false
}

export const getRestaurantDisplayName = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID) {
    return 'Chefs Gallery'
  }

  return restaurant?.name
}

export const getRestaurantWebsite = (restaurant) => {
  if (restaurant?.objectId === CHEFS_GALLERY_DEMO_ID) {
    return 'www.chefsgallery.com.au'
  }

  if (restaurant?.objectId === OPPA_POCHA_DEMO_ID) {
    return 'www.oppapocha.com.au'
  }

  return restaurant?.website
}

export const getLogoLink = (restaurant) => {
  if (restaurant?.objectId === MAMASAAN_ID) {
    return 'https://drive.google.com/uc?export=view&id=1JKczWich0i18YYM6MxI8InmdyIFGqn5g'
  }

  return restaurant?.branding.logoLink
}

export const getHeroImage = (restaurant) => {
  if (restaurant?.objectId === MAMASAAN_ID) {
    return 'https://drive.google.com/uc?export=view&id=1JKczWich0i18YYM6MxI8InmdyIFGqn5g'
  }

  return restaurant?.branding.heroImageLink
}
